// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

 export const environment = {
   production: false,
   api: {
    endpoint: 'https://karpro.co/API/public/index.php',
    token: 'Tm9raWE6MTIzNDU2',
  },
  socketURL: 'https://karpromechanic.myteamconnector.com:2103',
  FrontendUrl: 'https://karpromechanic.myteamconnector.com',
  PusherConfig: {
    Key: "bfb641c0e9acae7fbe8c",
    //cluster: "mt1"
  },
  userauth: {
    key: '_karpromechanicadmin_user',
    accessPages: '__karpromechanicadmin_user_accessPages',
    accessPagesPermission: '__karpromechanicadmin_user_accessPagesPermission'
  },
  currency: 'USD',
  authSessionKey: '_karpromechanicadmin_user'
};
