import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { NgxStarsComponent, NgxStarsModule } from 'ngx-stars';

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),    
    HttpClientModule,
    NgxStarRatingModule,
    //NgxStarsModule,
    //NgxStarsComponent,
    ChartsModule,
    //NgxMaskModule.forRoot(),
  ],
  providers: [ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
